<template>
  <div>
    <div ref="post" v-if="currentPost" class="post">
      <div class="post__header d-flex justify-center align-center">
        <div class="d-flex justify-center align-center">
          <v-chip :href="'#/projects/'+currentPost.project.id" link v-if="showProject && post.project">
            <v-avatar left>
              <v-img :src="currentPost.project.logotype_url"></v-img>
            </v-avatar>
            <span class="post__title">
              {{ currentPost.project.title }}
          </span>

          </v-chip>
          <UserLinkComponent :text="date.fromNow()" v-else-if="showUser && currentPost.author"
                             :user="currentPost.author"/>

        </div>
        <v-spacer/>
        <div v-if="showProject && post.project" :title="date.format('DD MMMM YYYY HH:mm')" class="post__date">
        <span v-if="$moment().diff(date, 's') > 60">
          <template v-if="$moment().diff(date, 'd') > 10">
             {{ date.local().format('DD MMMM YYYY') }}
          </template>
          <template v-else>
             {{ date.fromNow() }}
          </template>

        </span>
          <span v-else>
          Только что
        </span>
        </div>


      </div>
      <div v-if="!editMode">
        <PostText :text="currentPost.text" v-if="currentPost.text"/>
        <carousel class="post__img" :dots="false"
                  :responsive="{0:{items:1,nav:false},600:{items:Math.min(2, images.length),nav:false}}" :nav="false"
                  v-if="images.length">
          <v-img gradient="to top right, rgba(100,115,201,.1), rgba(25,32,72,.2)" aspect-ratio="3" max-height="300"
                 :height="images.length > 1 ? 300 : 450" :key="image.id" class="d-flex child-flex"
                 v-for="image in images"
                 @click="imageView = image; imageViewShow = true" :src="image.link"></v-img>

          <template slot="prev">
            <div v-if="images.length > 1" class="post_img__prev">
              <v-icon dark :size="50">
                mdi-chevron-left
              </v-icon>
            </div>
          </template>
          <template slot="next">
            <div v-if="images.length > 1" class="post_img__next">
              <v-icon dark :size="50">
                mdi-chevron-right
              </v-icon>
            </div>
          </template>

        </carousel>

        <carousel class="post__img" :dots="false"
                  :items="1" :nav="false"
                  v-if="videos.length">

          <video width="100%" controls muted :src="video.link" v-for="video in videos" :key="video.id"></video>

          <template slot="prev">
            <div v-if="videos.length > 1" class="post_img__prev">
              <v-icon dark :size="50">
                mdi-chevron-left
              </v-icon>
            </div>
          </template>
          <template slot="next">
            <div v-if="videos.length > 1" class="post_img__next">
              <v-icon dark :size="50">
                mdi-chevron-right
              </v-icon>
            </div>
          </template>

        </carousel>
        <div class="pa-4" v-if="files.length">
          Прикреплены файлы:
        </div>
        <div class="post__files" v-for="file in files" :key="file.id">
          <a class="file__link" target="_blank" :href="file.link">
            {{ file.filename }}
          </a>
        </div>
        <iframe v-if="docs.length > 0 && !images.length" width="100%" height="300"
                :src="'https://docs.google.com/viewer?embedded=true&url='+docs[0].link"/>
        <div class="post_footer">
          <canvas ref="like_canvas" style="width: 100%; top: 0; left: 0; height: 100%; position: absolute"/>
          <div class="d-flex align-center justify-center mt-2">
            <span class="mr-2" style="color: gray; font-size: 15px;">
          {{ currentPost.views }}
        </span>
            <v-icon color="gray" size="16">
              mdi-eye
            </v-icon>
          </div>
          <v-btn :disabled="!currentPost.can_like" rounded @click.stop="like" text color="primary" class="mt-2">
            {{ currentPost.likes.length }}
            <v-icon v-if="currentPost.was_liked" right>mdi-thumb-up</v-icon>
            <v-icon v-else right>mdi-thumb-up-outline</v-icon>
          </v-btn>

          <v-btn @click.stop="fullPostMode = true" rounded text color="primary" class="mt-2">{{
              currentPost.comments.length
            }}
            <v-icon right>mdi-comment-outline</v-icon>
          </v-btn>
          <v-spacer/>

          <v-menu v-if="currentPost.can_delete" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" color="black" icon>
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="currentPost.can_delete" @click="remove">
                <v-list-item-title>Удалить</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="currentPost.can_edit" @click="editMode = true">
                <v-list-item-title>Редактировать</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-dialog v-model="imageViewShow" v-if="imageView">
          <v-card>
            <v-img height="500" contain width="100%" :src="imageView.link"/>
            <v-card-text>
              {{ imageView.filename }}
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog max-width="800" v-model="fullPostMode">
          <PostComments @posted="$emit('posted')" :post="post"/>
        </v-dialog>
      </div>
      <div v-else>
        <v-btn @click="editMode = false" color="primary" rounded text small>Отменить редактирование</v-btn>
        <PostForm @saved="updated" :project_id="currentPost.project_id" :editPost="currentPost"/>

      </div>
    </div>
  </div>
</template>

<script>
import posts from "@/api/posts";
import UserLinkComponent from "@/components/Profile/UserLinkComponent";
import PostComments from "@/components/Feed/PostComments";
import carousel from 'vue-owl-carousel'
import PostText from "@/components/Feed/PostText";
import PostForm from "@/components/Feed/PostForm";
import JSConfetti from "js-confetti";
import analytics from "@/api/analytics";

export default {
  name: "Post",
  computed: {
    images() {
      return this.currentPost.files.filter(f => f.isImage);
    },
    videos() {
      return this.currentPost.files.filter(f => f.isVideo);
    },
    docs() {
      return this.currentPost.files.filter(f => f.isDoc);
    },
    files() {
      return this.currentPost.files.filter(f => !f.isImage && !f.isVideo);
    },
    date() {
      return this.$moment.utc(this.currentPost.created_at);
    }
  },
  props: {
    post: {
      type: Object
    },
    showUser: {
      type: Boolean
    },
    showProject: {
      type: Boolean
    },
    showLastComment: {
      type: Boolean,
      default: false
    },
  },
  components: {PostForm, PostText, PostComments, UserLinkComponent, carousel},
  data() {
    return {
      imageView: null,
      imageViewShow: false,
      fullPostMode: false,
      currentPost: null,
      editMode: false,
      isV: false
    }
  },
  methods: {
    isInViewport() {
      if (!this.$refs.post) {
        return false;
      }
      const rect = this.$refs.post.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)

      );
    },

    updated(post) {
      this.currentPost = post;
      this.editMode = false;
    },
    urlify(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function (url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
      })
    },
    remove() {
      posts.delete({id: this.currentPost.id}).then(() => {
        this.$emit('wasDeleted')
      })
    },

    like() {
      if (!this.currentPost.was_liked) {
        let jsConfetti = new JSConfetti({canvas: this.$refs.like_canvas});
        jsConfetti.addConfetti({
          confettiRadius: 50,
          emojiSize: 50,
          confettiNumber: 50, emojis: ['👍']
        });
      }
      posts.like({id: this.currentPost.id}).then((r) => {
        this.currentPost = {...this.currentPost, likes: r.data, was_liked: !this.currentPost.was_liked};
      })
    }
  },
  mounted() {
    this.currentPost = this.post;
    window.addEventListener('scroll', () => {
      if (!this.isV) {
        this.isV = this.isInViewport();
        if (this.isV) {
          analytics.postViewed(this.$store.state.user.currentUser.id || 0, this.post.id);
        }
      }
    })
  }
}
</script>

<style scoped>
.post {
  background: white;
  border-radius: 20px;
  position: relative;
  box-shadow: -2px 2px 4px -2px rgba(34, 60, 80, 0.1);
}


.post__header {
  padding: 10px 20px;
  display: flex;
}

.post_footer {
  display: flex;
  padding: 10px 20px;
  position: relative;
  z-index: 1;
}

.post_img__prev {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  z-index: 2;
  top: 50%;
  transform: translateY(-70%);
}

.post_img__next {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  z-index: 2;
  top: 50%;
  transform: translateY(-70%);
}

.post__img {
  position: relative;
}

.post__files {
  padding: 0 20px;
}

.post__date {
  font-size: 14px;
  font-weight: 300;
}

.post__title {
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
}

.file__link {
  text-decoration: none;
  z-index: 20;
  display: block;
}
</style>
