<template>
  <v-card>
    <v-card-title>
      Комментарии
    </v-card-title>
    <v-card-text v-if="post.can_comment">
      <v-textarea v-model="newComment.text" maxlength="200" auto-grow outlined filled
                  placeholder="Комментарий"/>
      <v-btn  :disabled="!newComment.text.length" @click="addComment" color="primary" rounded
             outlined>Запостить
      </v-btn>
    </v-card-text>

    <div class="pa-2">
      <CardUI :key="comment.id" v-for="comment in comments">
        <template v-slot:body>
          <PostText style="padding: 0; margin-top: 10px; z-index: 1000" :text="comment.text"/>
        </template>
        <template v-slot:header>
          <UserLinkComponent :user="comment.author"/>
          <v-spacer/>
          <div style="font-size: 12px;">
            {{$moment().diff($moment.utc(comment.created_at), 'd') > 3 ? $moment.utc(comment.created_at).format('DD.MM.YYYY HH:mm') : $moment.utc(comment.created_at).fromNow()}}
          </div>

        </template>
      </CardUI>
    </div>


  </v-card>
</template>

<script>
import CardUI from "@/components/UI/CardUI";
import UserLinkComponent from "@/components/Profile/UserLinkComponent";
import posts from "@/api/posts";
import PostText from "@/components/Feed/PostText";
export default {
  name: "PostComments",
  components: {UserLinkComponent, CardUI, PostText},
  props: ['post'],
  data(){
    return {
      comments: [],
      newComment: {
        text: ''
      }
    }
  },
  methods: {
    addComment(){
      posts.addComment({id: this.post.id, text: this.newComment.text}).then((r) => {
        this.comments = [r.data, ...this.comments]
        this.$emit('posted');
      })
      this.newComment.text = '';
    }
  },
  mounted() {
    posts.getComments({id: this.post.id}).then((r) => {
      this.comments = r.data;
    })
  }
}
</script>

<style scoped>

</style>
