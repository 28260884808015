<template>
  <div class="post__text">
    <div v-html="textWithLinks"/>
    <a @click="shortMode = false" v-if="shortMode && text.length > 200">Показать полностью</a>
  </div>
</template>

<script>
export default {
  name: "PostText",
  props: ['text'],
  data(){
    return {
      shortMode: true
    }
  },
  computed: {
      textWithLinks(){
        return this.urlify(this.shortMode ? this.text.slice(0, 200) : this.text);
      }
  },
  methods: {
    urlify(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(/<\/?[^>]+>/gi, '').replace(urlRegex, function (url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
      })
    },
  }
}
</script>

<style scoped>
.post__text {
  white-space: pre-wrap;
  padding: 0 20px;
  margin-bottom: 10px;
  overflow: hidden;
}

</style>
