<template>
  <v-card style="border-radius: 20px;" flat class="mb-2">
    <v-card-text>
      <v-textarea :rows="3" v-model="text" maxlength="5000" auto-grow outlined filled
                  :placeholder="placeholder">
        <template v-slot:append>
          <FileUploadV2 v-model="files">
            <template v-slot:activator="props">
              <v-btn icon @click="props.attachFiles">
                <v-icon>mdi-attachment</v-icon>
              </v-btn>
            </template>
          </FileUploadV2>
        </template>
      </v-textarea>
      <div class="mb-4" v-if="files.length">
        <FileHorizontalViewer :files="files"/>
      </div>
      <div class="d-flex align-center">
        <v-btn :loading="isPosting" @click="post" :disabled="!files.length && !text.length" color="primary"  rounded
               outlined>
          <span v-if="!editPost">
            Запостить
          </span>
          <span v-else>
            Сохранить
          </span>
        </v-btn>
        <span v-if="files.length" class="text--disabled ml-2">
        c {{ files.length }} {{ $plural(files.length, 'файлом', 'файлами', 'файлами') }}
      </span>
      </div>


    </v-card-text>
  </v-card>
</template>

<script>

import FileUploadV2 from "@/components/Feed/FileUploadV2";
import FileHorizontalViewer from "@/components/Feed/FileHorizontalViewer";
import posts from "@/api/posts";

export default {
  name: "PostForm",
  components: {FileUploadV2, FileHorizontalViewer},
  props: ['placeholder', 'project_id', 'editPost'],
  mounted() {
    if(this.editPost){
      this.files = this.editPost.files.map((f) => ({...f, remove: () => {
          this.files = this.files.filter((f2) => f2.id !== f.id);
        }}))
      this.text = this.editPost.text;
    }
  },
  data() {
    return {
      files: [],
      text: '',
      isPosting: false
    }
  },
  methods: {

    post() {
      this.isPosting = true;
      let data = {
        text: this.text,
        files: this.files.map((f) => f.id),
        project_id: this.project_id
      };
      if(this.editPost){
        data.post_id = this.editPost.id;
      }
      posts.makePost(data).then((r) => {
        this.$emit('posted');
        this.text = '';
        this.files = [];
        if(this.editPost){
          this.$emit('saved', r.data);
        }
      }).finally(() => {
        this.isPosting = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
